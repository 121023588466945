import Cookies from "js-cookie";

async function getServiceStatusUrl(serviceName) {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_SERVICE_STATUS}?service_name=${encodeURIComponent(serviceName)}`;
}

export async function getConfigTokensUrl() {
  const response = await fetch('/api.json');
  const data = await response.json();
  return `${data.API_CONFIG}`;  
}

export const updateTokensMax = async (configId, tokensMax, period) => {
  try {
    const API_URL = await getConfigTokensUrl();

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ConfigId: configId,
        TokensMax: tokensMax,
        period: period,
      }),
    });

    // Verificar si la respuesta es exitosa
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Error desconocido en la API");
    }

    const data = await response.json();
    return {
      success: true,
      message: data.message || "TokensMax actualizado exitosamente",
      updatedAttributes: data.updated_attributes,
    };
  } catch (error) {
    // Manejo de errores: devolver solo el texto del error
    return {
      success: false,
      message: error.message, // El texto del error
    };
  }
};




export const fetchServiceStatus = async (serviceName) => {
  const token = Cookies.get("accessToken");

  try {
    const API_SERVICE_STATUS = await getServiceStatusUrl(serviceName);
    const response = await fetch(API_SERVICE_STATUS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Error al obtener el estado del servicio");
    }

    const serviceStatus = await response.json();
    return serviceStatus;
  } catch (error) {
    console.error("Error al intentar obtener el estado del servicio:", error);
    throw error;
  }
};
