import React from 'react';
import './Changelog.css'; // Importa el archivo de estilos

function Changelog() {
  return (
    <div className='dark:bg-[#191A1A]'>
      <header className="-fixed">
        <h1>«Cesarin» Changelog</h1>
      </header>
      <div className="wrap-content dark:bg-[#191A1A]">
        <article className="changelog">
        <section className="version -planned">
            <div className="header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1rem', borderBottom: '1px solid #f3f3f3' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 className="version-num" style={{ marginRight: '10px' }}>Version 0.4.1-efectiva-5</h2>
                <img
                  src="https://img.shields.io/badge/terraform-%235835CC.svg?style=for-the-badge&logo=terraform&logoColor=white"
                  alt="Terraform Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
                <img
                  src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white"
                  alt="GitHub Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
                <img
                  src="https://img.shields.io/badge/pytest-%23ffffff.svg?style=for-the-badge&logo=pytest&logoColor=2f9fe3"
                  alt="Pytest Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
              </div>
              <time className="datetime" dateTime="2024-10-30" style={{ fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.6)' }}>In Coming</time>
            </div>
            
            <section>
              <div className="label-orange -s -blue mt-3">Next Release</div>
              <ul>
                <li><span className="label-inline -ghost -orange">TODO</span> - Mejora en la estrategia de consumo de datos (Chunks).</li>
                <li><span className="label-inline -ghost -orange">TODO</span> - Evaluación de cambio de base vectorial (PGVector actual).</li>
                <li><span className="label-inline -ghost -orange">TODO</span> - Implementación de Preguntas Frecuentes.</li>
                <li><span className="label-inline-wip -ghost -blue">WIP</span> - Mejoras en interfaz de Administración de archivos. Se esta trabajando en: Limitación de archivos cargados, paginación de documentos, buscador de documentos.</li>
                <li><span className="label-inline -ghost -blue">IDEA</span> - Agregar una interfaz de estadísticas de documentos.</li>
                <li><span className="label-inline -ghost -blue">TODO</span> - Integración con AD en el Login de usuarios.</li>
              </ul>
            </section>

            {/* <section>
              <div className="label -s -bg" style={{ background: 'indigo' }}>Fixed</div>
              <ul>
                <li><span className="label-inline -ghost -orange">TODO</span> Resolved compatibility issues with mobile browsers</li>
                <li>Addressed memory leak in data processing module</li>
                <li>Corrected display bug in dashboard module</li>
                <li>Fixed broken links in help documentation</li>
              </ul>
            </section> */}
          </section>
          <section className="version -planned">
            <div className="header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '1rem', borderBottom: '1px solid #f3f3f3' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 className="version-num" style={{ marginRight: '10px' }}>Version 0.4.1-efectiva-4</h2>
                <img
                  src="https://img.shields.io/badge/terraform-%235835CC.svg?style=for-the-badge&logo=terraform&logoColor=white"
                  alt="Terraform Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
                <img
                  src="https://img.shields.io/badge/github-%23121011.svg?style=for-the-badge&logo=github&logoColor=white"
                  alt="GitHub Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
                <img
                  src="https://img.shields.io/badge/pytest-%23ffffff.svg?style=for-the-badge&logo=pytest&logoColor=2f9fe3"
                  alt="Pytest Badge"
                  style={{ marginLeft: '10px', height: '24px' }}
                />
              </div>
              <time className="datetime" dateTime="2024-10-30" style={{ fontSize: '0.9rem', color: 'rgba(0, 0, 0, 0.6)' }}>Octubre 30, 2024</time>
            </div>
            
            <section>
              <div className="label -s -blue mt-3">Current Version</div>
              <ul>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Cambios gráficos en la interfaz. Se agrega un botón de Tour Virtual por cada componente.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se agrega contador de cantidad de archivos por cada colección, en el administrador de archivos.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se agrega listado de colecciones a todo el Chatbot, permitiendo a los usuarios segmentar la base de conocimiento dependiendo el conjunto que quiera utilizar.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se agrega listado de colecciones para que los usuarios invitados puedan seleccionar una colección de datos.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se hace mejora del modelo LLM y Embedding, utilizando el modelo anthropic.claude-3-haiku-20240307-v1:0 y para Embedding amazon.titan-embed-text-v2:0.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se actualiza versión de Langchain a 0.3.7 para uso de modelos V3 de Anthropic. Se actualiza la estructura de Langchain en nuestro backend para recibir el esquema de los nuevos modelos versión 3 de Anthropic.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se mejora el rendimiento de los componentes React, con estructuración en componentes más pequeños.</li>
                <li><span className="label-inline-done -ghost -green">DONE</span>- Se segmentan los archivos subidos en 3 colecciones por empresa, FinancieraEfectiva, Motorcorp y ConectaRetail.</li>
              </ul>
            </section>

            {/* <section>
              <div className="label -s -bg" style={{ background: 'indigo' }}>Fixed</div>
              <ul>
                <li><span className="label-inline -ghost -orange">TODO</span> Resolved compatibility issues with mobile browsers</li>
                <li>Addressed memory leak in data processing module</li>
                <li>Corrected display bug in dashboard module</li>
                <li>Fixed broken links in help documentation</li>
              </ul>
            </section> */}
          </section>
        </article>
      </div>
    </div>
  );
}

export default Changelog;
