import React, { useEffect, useState, useContext } from "react";
import LeftNav from "../components/LeftNav";
import ChatContainer from "../components/ChatContainer";
import { RiMoonFill, RiSunLine } from "react-icons/ri";
import { ContextApp } from "../utils/Context";
import { useNavigate } from "react-router-dom";
import { getConfigTokensUrl } from "../services/serviceStatusApi";
import ServiceMenuModal from "../components/modal/ServiceMenuModal";

function Home() {

  const [configOffChat, setConfigOffChat] = useState(null); // Estado para el flag
  const [isLoading, setIsLoading] = useState(true); // Estado de carga
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    if (!savedTheme) {
      localStorage.setItem("theme", "light"); // Establecer "light" como predeterminado en localStorage
      return false; // Tema claro por defecto
    }
    return savedTheme === "dark";
  });

  const [isModalOpen, setIsModalOpen] = useState(false); // Controlar la apertura del modal

  const { userRole, isLoggedIn } = useContext(ContextApp); // Obtener el rol y estado de sesión del contexto
  const navigate = useNavigate(); // Hook para redirigir

  const toggleTheme = () => {
    setIsDarkMode((prev) => {
      const newTheme = !prev;
      const root = document.documentElement;

      if (newTheme) {
        root.classList.add("dark");
        localStorage.setItem("theme", "dark");
      } else {
        root.classList.remove("dark");
        localStorage.setItem("theme", "light");
      }

      return newTheme;
    });
  };

  useEffect(() => {
    const theme = localStorage.getItem("theme") || "light";
    const root = document.documentElement;

    if (theme === "dark") {
      root.classList.add("dark");
    } else {
      root.classList.remove("dark");
    }
  }, []);

  useEffect(() => {
    const fetchConfigOffChat = async () => {
      try {
        const API_CONFIG = await getConfigTokensUrl();
        const response = await fetch(API_CONFIG, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const configData = await response.json();
        console.log(configData.chatbot_enabled);
        setConfigOffChat(configData.chatbot_enabled);
      } catch (error) {
        console.error("Error al obtener el estado del chatbot:", error);
        setConfigOffChat(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchConfigOffChat();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-[#F3F6FB] dark:bg-[#191A1A]">
        <p className="text-xl font-bold text-gray-700 dark:text-gray-300">
          Cargando...
        </p>
      </div>
    );
  }

  if (!configOffChat) {
    return (
      <div className="flex items-center justify-center w-screen h-screen bg-[#F3F6FB] dark:bg-[#191A1A]">
        <div className="flex items-center justify-center">
          {/* Columna Izquierda: Imagen del Personaje */}
          <div className="flex-shrink-0 -mr-1 lg:mr-0">
            <img
              src="character-position.png" // Cambia a la ruta real de tu imagen
              alt="Character"
              className="max-w-[150px] lg:max-w-[200px] -translate-x-3 lg:-translate-x-4"
            />
          </div>
          {/* Columna Derecha: Mensaje */}
          <div className="flex flex-col lg:flex-row items-center bg-white dark:bg-[#1F2125] p-8 rounded-lg shadow-lg max-w-4xl">
            <div className="text-center lg:text-left">
              <p className="text-2xl font-bold text-red-500 dark:text-red-400 mb-4">
                Lo sentimos, Cesarin se encuentra en mantenimiento.
              </p>
              <p className="text-lg text-gray-700 dark:text-gray-300 mb-6">
                Contacte al administrador para más información.
              </p>
              {!isLoggedIn ? (
                <button
                  className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600 transition"
                  onClick={() => navigate("/login")} // Redirige a la página de inicio de sesión
                >
                  Iniciar Sesión
                </button>
              ) : userRole === "admin" ? (
                <button
                  className="px-6 py-3 bg-green-500 text-white rounded-lg shadow hover:bg-green-600 transition mt-4"
                  onClick={() => setIsModalOpen(true)} // Abre el modal
                >
                  Configurar Chatbot
                </button>
              ) : (
                <p className="text-lg text-gray-700 dark:text-gray-300">
                  No tiene permisos para configurar el chatbot.
                </p>
              )}
            </div>
          </div>
        </div>
        {isModalOpen && (
          <ServiceMenuModal onClose={() => setIsModalOpen(false)} />
        )}
      </div>
    );
  }

  return (
    <div className="flex w-screen h-screen relative bg-[#F3F6FB] dark:bg-[#191A1A]">
      <LeftNav />
      <ChatContainer />
      
      {isModalOpen && (
        <ServiceMenuModal onClose={() => setIsModalOpen(false)} />
      )}
    </div>
  );
}

export default Home;
