import React, { useState, useContext, useEffect, useRef } from "react";
import { AiOutlinePlus, AiFillDelete } from "react-icons/ai";
import { LuPanelLeftClose } from "react-icons/lu";
import { SlOptions } from "react-icons/sl";
import { ContextApp } from "../utils/Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { fetchChatHistory } from "../services/uploadFilesApi";
import FileManagementModal from "./modal/FileManagementModal";
import { deleteTopic } from "../services/topicApi";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import WhatsAppMenuModal from "./modal/WhatsAppMenuModal";
import UserManagementModal from "./modal/UserManagementModal"; 
import ServiceMenuModal from "./modal/ServiceMenuModal";
import FilesAnalysisModal from "./modal/FilesAnalysisModal"
import {
  RiChat3Line,
  RiFileTextLine,
  RiQuestionLine,
  RiRobot2Line,
  RiSettings4Line,
  RiMoonFill,
  RiSunLine,
  RiDashboardLine,
  RiArrowRightSLine,
} from "react-icons/ri";

function LeftNav() {
  const {
    setShowSlide,
    showSlide,
    loadChatHistoryForTopic,
    topics,
    deleteTopicFromList,
    reloadTopicsTrigger,
    setReloadTopicsTrigger,
    resetChat,
    isWaitingForResponse,
    isGuest,
    userRole,
    username,
    setUsername,
  } = useContext(ContextApp);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showFileManagementModal, setShowFileManagementModal] = useState(false);
  const [showFilesAnalysisModal, setshowFilesAnalysisModal] = useState(false);
  const [showWhatsAppMenuModal, setShowWhatsAppMenuModal] = useState(false);
  const [showUserManagementModal, setShowUserManagementModal] = useState(false);
  const [showServiceMenuModal, setShowServiceMenuModal] = useState(false);
  const [whatsappShow, setWhatsappShow] = useState(false);
  const dropdownRef = useRef(null);
  const [config, setConfig] = useState({});
  const [textConfig, setTextConfig] = useState({});
  const [isDark, setIsDark] = useState(false);

  useEffect(() => {
    const isDarkMode =
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    setIsDark(isDarkMode);
    document.documentElement.classList.toggle("dark", isDarkMode);
    if(localStorage.theme == "dark"){
      document.documentElement.setAttribute(
        "data-color-scheme","dark"        
      );
    }else{
      document.documentElement.setAttribute(
        "data-color-scheme","light"        
      )}
  }, []);

  const toggleTheme = () => {
    const newTheme = !isDark;
    setIsDark(newTheme);
    localStorage.theme = newTheme ? "dark" : "light";
    document.documentElement.setAttribute(
      "data-color-scheme",
      isDark ? "light" : "dark"
    );
    document.documentElement.classList.toggle("dark", newTheme);
  };


  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("expirationDate");
    Cookies.remove("refreshToken");
    Cookies.remove("username");
    setUsername(null);
    navigate("/login");
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/configuration.json");
        const data = await response.json();
        setConfig(data);
        setWhatsappShow(data.WHATSAPP_SHOW);
      } catch (error) {
        console.error("Error loading config:", error);
      }
    };
  
    const fetchTextConfig = async () => {
      try {
        const response = await fetch("/text.json");
        const data = await response.json();
        setTextConfig(data);
      } catch (error) {
        console.error("Error loading text config:", error);
      }
    };
  
    fetchConfig();
    fetchTextConfig();
  }, []);

  
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const storedUsername = Cookies.get("username");
    if (storedUsername !== username) {
      setUsername(storedUsername);
    }
  }, [username, setUsername]);

  const handleDeleteTopic = async (topicId) => {
    try {
      await deleteTopic(topicId);
      setReloadTopicsTrigger((prev) => prev + 1);
    } catch (error) {
      console.error("Error al eliminar el tópico:", error);
    }
  };

  const handleTopicClick = async (topic) => {
    try {
      const history = await fetchChatHistory(topic);
    } catch (error) {
      console.error(
        "Error al intentar cargar el historial del chat para el tópico:",
        topic,
        error
      );
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div key={reloadTopicsTrigger}>
      <div
        className={
          !showSlide
            ? "flex flex-col h-screen bg-[#fdfeff] dark:bg-[#1f2121] rounded-3xl m-4 sm:h-[calc(110vh-9em)] md:h-[calc(110vh-12em)] lg:h-[calc(110vh-12em)] w-[300px] border-r lg:flex items-center justify-between text-black dark:text-white dark:border-stone-950 translate-x-0"
            : "hidden"
        }
      >
        {showFileManagementModal && (
          <FileManagementModal
            onClose={() => setShowFileManagementModal(false)}
          />
        )}
         {showFilesAnalysisModal && (
          <FilesAnalysisModal
            onClose={() => setshowFilesAnalysisModal(false)}
          />
        )}
        {showWhatsAppMenuModal && (
          <WhatsAppMenuModal onClose={() => setShowWhatsAppMenuModal(false)} />
        )}
        {showUserManagementModal && (
          <UserManagementModal onClose={() => setShowUserManagementModal(false)} />
        )}
        {showServiceMenuModal && (
          <ServiceMenuModal onClose={() => setShowServiceMenuModal(false)} />
        )}

        <div className="flex bg-red flex-col items-center w-full h-full">
        <div className="flex flex-col justify-center items-center mt-6 mb-4 relative">
        <img
          //src={LogoImg}
          src="logo-cesarin.png"
          className="max-w-full max-h-40 w-auto h-auto mx-auto dark:filter dark:invert dark:brightness-0"
          alt="Logo"
          />

  <div className="w-24 h-1 dark:bg-white bg-gradient-to-r bg-black mt-2 rounded-full"></div>
  
  <hr className="w-full border-gray-700 mt-4 opacity-40" />
</div>

          <div className="w-full border-b-gray-900 px-4 mt-5 flex dark:text-white justify-between items-center">
            <span
              className={`dark:border-gray-700 border-b-gray-900 text-white rounded-xl bg-[#5762F7] hover:bg-[#3e48d0] w-[calc(100%-4rem)] py-2 text-xs flex gap-1 items-center justify-center cursor-pointer ${
                isWaitingForResponse ? "opacity-50 pointer-events-none" : ""
              }`}
              onClick={resetChat}
            >
              <AiOutlinePlus fontSize={18} />
              {textConfig.LEFTNAV_TEXT_NEW_CHAT}
            </span>
            <span
              className="border border-white rounded px-3 py-[9px] flex items-center justify-center cursor-pointer"
              title="Close sidebar"
              onClick={() => setShowSlide(!showSlide)}
            >
              <LuPanelLeftClose />
            </span>
          </div>
          <div className="flex-1 w-full px-4 mt-5 overflow-y-auto max-h-[calc(100vh-200px)]">
            {topics.map((topic, index) => (
              <div
                key={index}
                className={`rounded w-full py-3 px-2 text-xs my-2 flex items-center justify-between cursor-pointer hover:text-white hover:bg-[#5762F7] overflow-hidden relative ${
                  isWaitingForResponse ? "opacity-50 pointer-events-none" : ""
                }`}
                onClick={() => loadChatHistoryForTopic(topic.id)}
              >
                <svg stroke="currentColor" fill="currentColor" viewBox="0 0 24 24" class="useEffect(() => { setFilteredTopics( topics.filter((topic) => topic.name.toLowerCase().includes(searchTerm.toLowerCase()), ), ); }, [searchTerm, topics]);scale-x-[-1] transform text-[#1E1F22] dark:text-[#c9c9c9] lg:text-base" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M7.29117 20.8242L2 22L3.17581 16.7088C2.42544 15.3056 2 13.7025 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C10.2975 22 8.6944 21.5746 7.29117 20.8242ZM7.58075 18.711L8.23428 19.0605C9.38248 19.6745 10.6655 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 13.3345 4.32549 14.6175 4.93949 15.7657L5.28896 16.4192L4.63416 19.3658L7.58075 18.711Z"></path></svg>
                <span
                  className="flex-1 truncate"
                  style={{
                   
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                  }}
                >
                  {truncateText(topic.name, 15)}
                </span>
                <AiFillDelete
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteTopicFromList(topic.id);
                  }}
                  className="text-red-500 hover:text-white cursor-pointer ml-4 text-xl"
                  style={{ transition: "color 0.3s ease" }}
                  // onMouseEnter={(e) =>
                  //   e.currentTarget.parentNode.firstChild.classList.add(
                  //     "text-red-500"
                  //   )
                  // }
                  onMouseLeave={(e) =>
                    e.currentTarget.parentNode.firstChild.classList.remove(
                      "text-red-500"
                    )
                  }
                />
              </div>
            ))}
          </div>
          <div className="w-full rounded-3xl text-black border-t-2 dark:border-t-[#363637] flex flex-col gap-2 items-center justify-center py-5">
            <span className="rounded w-full py-2 px-2 mt-5 text-xs flex gap-1 items-center justify-between cursor-pointer transition-all duration-300">
              {isGuest ? (
                <>
                 <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-black dark:text-white">
                    {textConfig.LEFTNAV_TEXT_GUEST}
                  </span>
                  <button
                    className="rounded-md px-4 py-2 text-sm font-medium bg-[#5762F7] hover:bg-[#3e48d0] text-white uppercase cursor-pointer transition-colors duration-300"
                    onClick={() => navigate("/login")}
                  >
                    {textConfig.LEFTNAV_TEXT_LOGIN}
                  </button>
                </>
              ) : (
                <>
                  <span className="flex gap-2 ml-3 mr-1 items-center justify-center text-sm font-bold text-black dark:text-white">
                    <FontAwesomeIcon icon={faUser} />
                    {username || textConfig.LEFTNAV_TEXT_USER}
                  </span>
                  <span
                    className="rounded-md px-4 py-2 text-sm font-medium hover:bg-[#5762F7] hover:text-white uppercase text-gray-500 cursor-pointer"
                    onClick={(event) => {
                      event.stopPropagation();
                      setShowDropdown(!showDropdown);
                    }}
                  >
                    <SlOptions />
                  </span>
                </>
              )}
            </span>
            {showDropdown && !isGuest && (
              <div
                ref={dropdownRef}
                className="absolute bottom-[60px] translate-x-[20%] w-48 bg-white text-black rounded shadow-md z-60"
              >
                <ul>
                  {userRole === "admin" && (
                    <>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowUserManagementModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        {textConfig.LEFTNAV_TEXT_ADMIN_USER || "Administrar usuarios"}
                      </li>
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowFileManagementModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        {textConfig.LEFTNAV_TEXT_FILE_MANAGEMENT || "Administrar archivos"}
                      </li>
                      {/* <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setshowFilesAnalysisModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        Administrador de archivos v.2
                      </li> */}
                      <li
                        className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                        onClick={() => {
                          setShowServiceMenuModal(true);
                          setShowDropdown(false);
                        }}
                      >
                        Configuración de Tokens
                      </li>
                    </>
                  )}
                  <li
                    className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={handleLogout}
                  >
                    {textConfig.LEFTNAV_TEXT_LOGOUT || "Cerrar sesión"}
                  </li>
                </ul>
              </div>
            )}
          </div>
<div className="mx-auto mb-5 flex w-max items-center justify-center gap-3 rounded-full bg-[#E2E2E2] px-2 py-1 shadow-md dark:bg-[#3F424A] dark:shadow-lg">
  <button
    className="flex h-6 w-6 items-center justify-center rounded-full transition-all duration-300 dark:bg-[#64666D] dark:shadow-inner dark:shadow-black hover:scale-105 hover:bg-[#b3b3b3] dark:hover:bg-[#7a7c84]"
    onClick={toggleTheme}
    title="Dark Mode"
  >
    <RiMoonFill size={12} className="text-black dark:text-white" />
  </button>
  <button
    className="flex h-6 w-6 items-center justify-center rounded-full transition-all duration-300 shadow-md shadow-gray-600 dark:shadow-none hover:scale-105 hover:bg-[#d4d4d4] dark:hover:bg-[#4f525a]"
    onClick={toggleTheme}
    title="Light Mode"
  >
    <RiSunLine size={12} className="text-black dark:text-gray-500 hover:dark:text-white" />
  </button>
  
</div>
        </div>
      </div>
    </div>
  );
}

export default LeftNav;