import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { updateTokensMax, getConfigTokensUrl } from "../../services/serviceStatusApi";

function ServiceMenuModal({ onClose }) {
  const [maxTokens, setMaxTokens] = useState("");
  const [numDocuments, setNumDocuments] = useState("4");
  const [inputTokensUsed, setInputTokensUsed] = useState(0);
  const [outputTokensUsed, setOutputTokensUsed] = useState(0);
  const [period, setPeriod] = useState("");
  const [tokensMax, setTokensMax] = useState(0);
  const [isChatbotActive, setIsChatbotActive] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [textConfig, setTextConfig] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchTextConfig = async () => {
    try {
      const response = await fetch("/text.json");
      const data = await response.json();
      setTextConfig(data);
    } catch (error) {
      toast.error("Error al cargar la configuración de texto");
    }
  };

  const getConfigTokens = async () => {
    try {
      const API_CONFIG = await getConfigTokensUrl();

      const response = await fetch(API_CONFIG, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Error al obtener los tokens");
      }

      const tokensMetrics = await response.json();

      setInputTokensUsed(tokensMetrics.total_input_tokens || 0);
      setPeriod(tokensMetrics.month);
      setTokensMax(tokensMetrics.tokens_max || 0);
      setOutputTokensUsed(tokensMetrics.total_output_tokens || 0);
    } catch (error) {
      toast.error("Error al cargar métricas de tokens");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTextConfig();
    getConfigTokens();
  }, []);

  useEffect(() => {
    setMaxTokens(tokensMax);
  }, [tokensMax]);

  const handleBackdropClick = (event) => {
    if (event.target.classList.contains("modal-backdrop")) {
      onClose();
    }
  };

  const confirmSave = () => {
    setShowConfirmation(true);
  };

  const handleSaveSettings = async () => {
    setIsButtonDisabled(true);

    try {
      const result = await updateTokensMax("Chatbot", maxTokens, period);
      console.log(result);
      if (result.success) {
        setTokensMax(maxTokens);
        toast.success("TokensMax actualizado correctamente");
        onClose();
        window.location.reload(); // Recargar la página
      } else {
        toast.error(`Error al actualizar TokensMax: ${result.message}`);
      }
    } catch (error) {
      toast.error(`Error inesperado: ${error.message}`);
    } finally {
      setIsButtonDisabled(false);
      setShowConfirmation(false); // Cerrar la confirmación
    }
  };

  return ReactDOM.createPortal(
    <div
      className="modal-backdrop fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="bg-gray-100 dark:bg-[#1c1d1d] p-8 rounded-lg w-full max-w-lg mx-4 h-auto flex flex-col shadow-lg">
        <h2 className="text-2xl font-bold text-center dark:text-white mb-4">Configuración del Chatbot</h2>
        {isLoading ? (
          <div className="text-center py-4">
            <p className="text-lg font-medium text-gray-700 dark:text-gray-200">Cargando...</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-4">
            {/* Input para Máximo de Tokens */}
            <div>
              <label htmlFor="maxTokens" className="block text-sm font-medium text-gray-700 dark:text-white mb-1">
                Máximo de Tokens
              </label>
              <input
                id="maxTokens"
                type="number"
                value={maxTokens}
                onChange={(e) => setMaxTokens(e.target.value)}
                placeholder="Ej: 1000"
                className="w-full py-2 px-3 rounded-lg border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                disabled={isButtonDisabled}
              />
            </div>

            {/* Área de métricas */}
            <div className="bg-white dark:bg-[#131414] p-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-700 dark:text-gray-100 mb-2">Métricas Mensuales ({period})</h3>
              <div className="flex justify-between">
                <p className="text-sm text-gray-600 dark:text-gray-100">Tokens de Entrada Usados:</p>
                <p className="text-sm font-bold text-gray-800 dark:text-gray-100">{inputTokensUsed}</p>
              </div>
              <div className="flex justify-between mt-2">
                <p className="text-sm text-gray-600 dark:text-gray-100">Tokens de Salida Usados:</p>
                <p className="text-sm font-bold text-gray-800 dark:text-gray-100">{outputTokensUsed}</p>
              </div>
              <div className="flex justify-between mt-2">
                <p className="text-sm text-gray-600 dark:text-gray-100">Tokens Total Usados:</p>
                <p className="text-sm font-bold text-gray-800 dark:text-gray-100">{inputTokensUsed + outputTokensUsed}</p>
              </div>
            </div>
          </div>
        )}
        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={onClose}
            className="py-3 px-6 rounded-lg text-white bg-red-500 hover:bg-red-700 transition duration-150"
          >
            Cancelar
          </button>
          <button
            onClick={confirmSave}
            className={`py-3 px-6 rounded-lg text-white ${
              isButtonDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-700"
            } transition duration-150`}
            disabled={isButtonDisabled}
          >
            Guardar
          </button>
        </div>

        {/* Cuadro de confirmación */}
        {showConfirmation && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex justify-center items-center">
            <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm">
              <p className="text-center text-lg font-light text-gray-800 dark:text-white mb-4">
              ¿Estás seguro de realizar esta acción? Si guardas volverás a reactivar el chatbot.
              </p>
              <div className="flex justify-between">
                <button
                  onClick={() => setShowConfirmation(false)}
                  className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded-lg text-gray-800"
                >
                  No
                </button>
                <button
                  onClick={handleSaveSettings}
                  className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-white"
                >
                  Sí
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
}

export default ServiceMenuModal;
